@use "mixins" as *
.sportResultsHeader
  background: var(--sport_resultsBg)
  position: sticky
  top: var(--headerHeight)
  z-index: 4
  padding: 0 8px 8px
  + div
    min-height: var(--withHeaderHeight)
    position: relative

  > h3
    text-transform: uppercase
    font-size: 13px
    @include bold
    min-height: 26px
    @include flex

  > nav
    @include flex
    > button
      flex: 1
      max-width: 70px
      display: flex
      flex-direction: column
      height: 46px
      font-size: 11px
      text-transform: uppercase
      @include bold
      background: var(--sport_resultsItemBg)
      border-radius: 4px
      overflow: hidden
      padding: 0
      color: rgtb(var(--primaryColor))
      min-width: auto
      &:not(:first-of-type)
        margin-left: 8px
      > span
        @include flex
        justify-content: center
        width: 100%
        flex: 1

      > span:first-of-type
        font-size: 18px
        border-bottom: 1px solid var(--sport_resultsDateBorderDark)
        background: var(--sport_resultsDateBg)
        padding-top: 1px

      > span:last-of-type
        border-top: 1px solid var(--sport_resultsDateBorderLight)
      &.active
        background: var(--sport_resultsItemActiveBg)
        color: #fff
        > span:first-of-type
          border-bottom-color: var(--sport_resultsDateActiveBorderDark)
          background: var(--sport_resultsActiveDateBg)
        > span:last-of-type
          border-top-color: var(--sport_resultsDateActiveBorderLight)
